import './team.css';

const Team = () => {
  return (
    <section
      style={{ backgroundImage: "url(./assets/img/backgroundTeam.svg)" }}
      className="section bg-img"
    >
      <div className="container">
        <h1 className={"font-weight-bold"}>
          Our Team
          <hr
            className="w-90px ml-0 mt-0 rounded rounded-lg"
            style={{ backgroundColor: "#AA2B33", height: "4px" }}
          />
        </h1>
        <p className={"text-dark mb-10"}>
          {"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto\n" +
            "beatae vitae dicta sunt explicabo.. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo\n" +
            "inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo."}
        </p>

        <img src={"assets/img/navTeam.svg"} alt="navTeam" />

        <div className="row mt-8">
          <div className="col-lg-3">
            <h4 className={"card-text"}>{"ATEBA STEPHANE"}</h4>
            <small>{"CEO/Operational Manager"}</small>
            <hr className="w-200px ml-0 rounded rounded-lg bg-secondary" />
            <p>
              {"Stéphane Ateba founded African Brand Protection in 2019 as the CEO, where he applies \n" +
                  "his hard-earned experience in African country. During his 11 years career, Stéphane Ateba\n" +
                  "participated and lead multiple complex investigations and conduct several Enforcement \n" +
                  "across African country he had very good knowledge of African country. He has been able \n" +
                  "to develop good relationship with the various LEA’s and justice departments in the \n" +
                  "countries that he has had to operate he also has a good knowledge of the industry of the \n" +
                  "fight against counterfeiting.\n"}
            </p>
            <div className="social">
              <i className={"fa fa-2x fa-facebook mr-5"} />
              <i className={"fa fa-2x fa-google-plus text-danger mr-5"} />
              <i className={"fa fa-2x fa-twitter mr-5"} />
              <i className={"fa fa-2x fa-instagram "} />
            </div>
          </div>
          <div className="col-lg-3">
            <h4 className={"card-text"}>{"MALICK MOUNPAIN\n"}</h4>
            <small>{"Team Leader of field operative\n"}</small>
            <hr className="w-200px ml-0 rounded rounded-lg bg-secondary" />
            <p>
              {"Having worked for other companies for 8 years as field investigator, Malick has extensive field \n" +
                  "experience as an investigator, he has been able to put in place investigative techniques that have \n" +
                  "always helped him to lead to these missions he has a perfect knowledge of the fields of the \n" +
                  "investigations have always carried out these missions which have always satisfied our clients."}
            </p>
            <div className="social">
              <i className={"fa fa-2x fa-facebook mr-5"} />
              <i className={"fa fa-2x fa-google-plus text-danger mr-5"} />
              <i className={"fa fa-2x fa-twitter mr-5"} />
              <i className={"fa fa-2x fa-instagram "} />
            </div>
          </div>
          <div className="col-lg-3">
            <h4 className={"card-text"}>{"Mr. Étienne Ndenga"}</h4>
            <small>{"Deputy Manager"}</small>
            <hr className="w-200px ml-0 rounded rounded-lg bg-secondary" />
            <p>
              {"Holds a doctorate in business law from the University of Nantes-France. Specialized in OHADA \n" +
                  "business law. In addition, teacher, coordinator of masters in private law at the Catholic University \n" +
                  "of Central Africa. Particularly interested in issues related to competition law and intellectual \n" +
                  "property.\n"}
            </p>
            <div className="social">
              <i className={"fa fa-2x fa-facebook mr-5"} />
              <i className={"fa fa-2x fa-google-plus text-danger mr-5"} />
              <i className={"fa fa-2x fa-twitter mr-5"} />
              <i className={"fa fa-2x fa-instagram "} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Team;
