import NavBar from "../components/NavBar";
import Header from "../components/Header";
import WhatWeDoSection from "../components/WhatWeDoSection";
import Footer from "../components/Footer";

const WhatWeDo = () => {
  const whatWeDo = "What we do";
  return (
    <>
      <NavBar bg={"bg-light"}/>
      <Header title={whatWeDo} />
      <WhatWeDoSection />
      <Footer />
    </>
  );
};

export default WhatWeDo;
