const NotFound = () => {
  return (
      <div className="container">
        <div className="row no-margin h-fullscreen" style={{paddingTop: "10%"}}>

          <div className="col-12 mt-7">
            <div className=" mx-auto text-center">
              <h1 className="text-secondary lh-1" style={{fontSize: "200px"}}>404</h1>
              <hr className="w-30px"/>
              <h3 className="text-uppercase">Page not found!</h3>

              <p className="lead">Seems you're looking for something that doesn't exist.</p>

            </div>
          </div>

        </div>
      </div>
  )
}

export default NotFound