const About = () => {
  return (
    <section className={"section"}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <img src={"assets/img/shield.svg"} alt="shield" />
          </div>
          <div className="col-lg-6 col-md-6">
            <h1>About us</h1>
            <hr
              className="w-90px ml-0 mt-0 rounded rounded-lg"
              style={{ backgroundColor: "#AA2B33", height: "4px" }}
            />
            <h6>Protecting Brands Is Important</h6>
            <p>
              Reputation of a brand drives how the products will perform in the
              marketplace. A strong brand is what many consumers use to make the
              final purchasing decision. It represents “contract of
              expectations” between the company and the consumer. When the
              reputation of a brand is damaged, the consumer is less likely to
              trust that the “contract of expectations” will be satisfactorily
              met. Damage to a brand is usually more expensive to
            </p>
            <div className="container">
              <div className="row mb-3">
                <img src={"assets/img/vision.svg"} alt="" />
                <div className="media-body mt-2 ml-5">
                  <h5>Vision</h5>
                  <p>Protect your brand and your company is our priority</p>
                </div>
              </div>
              <div className="row mb-3">
                <img src={"assets/img/mission.svg"} alt="" />
                <div className="media-body mt-2 ml-5">
                  <h5>Mission</h5>
                  <p>
                    Protection of brands by detection, investigation, and
                    enforcement on violators
                  </p>
                </div>
              </div>
              <div className="row mb-3">
                <img src={"assets/img/value.svg"} alt="" />
                <div className="media-body mt-2 ml-5">
                  <h5>Value</h5>
                  <p>
                    Trem aperiam, eaque ipsa quae ab illo inventore veritatis et
                    quasi vitae dicta sunt explicabo sequi nesciunt.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default About;
