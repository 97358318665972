import Home from "./UI/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import News from "./UI/News";
import Career from "./UI/Career";
import Contact from "./UI/Contact";
import WhatWeDo from "./UI/WhatWeDo";
import NotFound from "./UI/NotFound";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={"/"} element={<Home />} exact />
        <Route path={"/news"} element={<News />} exact />
        <Route path={"/career"} element={<Career />} exact />
        <Route path={"/contact"} element={<Contact />} exact />
        <Route path={"/whatwedo"} element={<WhatWeDo />} exact />
        <Route path={"*"} element={<NotFound />} exact />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
