const Text = () => {
  return (
    <div id={"text"} className="container py-5 mt-8">
        <p>
          Reputation of a brand drives how the products will perform in the
          marketplace. A strong brand is what many consumers use to make the
          final purchasing decision. It represents “contract of expectations”
          between the company and the consumer. When the reputation of a brand
          is damaged, the consumer is less likely to trust that the “contract of
          expectations” will be satisfactorily met. Damage to a brand is usually
          more expensive to protect it.
        </p>
    </div>
  );
};
export default Text;
