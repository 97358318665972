const CareerSection = () => {
  return (
    <section className="section">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <p>
              {"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi\n" +
                "ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum\n"}
            </p>
          </div>
          <div className="col-12 mt-10">
            <form>
              <div className="row">
                <div className="col">
                  <label htmlFor="">
                    First & Last Name{" "}
                    <span className={"text-danger"}>{" *"}</span>
                  </label>
                  <input
                    className={"form-control border border-dark"}
                    type="text"
                  />
                </div>
                <div className="col">
                  <label htmlFor="">
                    Email<span className={"text-danger"}>{" *"}</span>
                  </label>
                  <input
                    className={"form-control border border-dark"}
                    type="text"
                  />
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-12">
                  <label>
                    Brief introduction about yourself{" "}
                    <span className={"text-danger"}>{" *"}</span>
                  </label>
                  <textarea name="description" id="" cols="155" rows="10" />
                </div>
              </div>
              <div className="row mb-5">
                <div className="col">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="customFile"
                    accept=".png, .jpg, .jpeg"
                  />
                  <label className="btn btn-secondary btn-label" htmlFor="customFile">
                      <label>
                          <i className={"fa fa-upload"}/>
                      </label>
                      import CV
                  </label>
                </div>
              </div>
              <button
                className={
                  "btn btn-bg-color btn-label text-white py-5 rounded rounded-sm"
                }
              >
                Submit your application
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CareerSection;
