/* eslint-disable */
import {Link} from "react-router-dom";

const Footer = () => {
  return (
    <footer
      style={{
        backgroundRepeat: "no-repeat",
        backgroundImage: "url(./assets/img/footerBg1.png)",
        backgroundPosition: "top",
        border: "none",
      }}
      className="footer py-7 text-white bg-img "
    >
      <div className="container ">
        <div className="row gap-y mt-10">
          <div className="col-md-4 col-xl-4 mr-5">
            <p>
                <img
                  // style={{height: "100px", width: "150px"}}
                  src={"assets/img/footer_logo.png"}
                  alt="logo african brand transparent"
                />
            </p>
            <p>
              {"African Brand Protection (ABP) : Cover the follow country : Cameroun, \n" +
                  "Gabon, Congo Brazzaville, Congo Kinshasa, Nigéria, Benin, Togo, \n" +
                  "Côte d’Ivoire, Sénégal Mali, Kenya, Ouganda, Mozambique, \n" +
                  "Zimbabwe Tanzanie Etc."}
            </p>
          </div>

          <div className="col-sm-4 col-md-3 col-xl-2 mt-4 mr-5">
            <h6 className="mb-4 mt-1">
              <p className={"font-weight-bold font-size-20"}>Get in touch</p>
              <hr
                className="w-50px ml-0 mt-4 rounded rounded-lg"
                style={{ backgroundColor: "white", height: "4px" }}
              />
            </h6>
            <div className="nav flex-column">
              <p className="nav-link mb-0">
                <span className={"font-weight-bold"}>Address</span>
              </p>
              <p className="nav-link mt-4 mb-0">
                <i className={"fa fa-map-marker"} />
                <span className={"ml-2 font-size-10"}>Yaoundé Cameroun,</span>
              </p>
              <span className={"ml-4 font-size-10"}>Rue Avenue Happy – BP:4353</span>
              <p className="nav-link">
                <i className={"fa fa-clock-o"} />
                <span className={"ml-2 font-size-11"}>
                  Mon-Fri 8:00 AM - 9:00 PM
                </span>
              </p>
            </div>
          </div>

          <div className="col-sm-4 col-md-3 col-xl-2 mt-9">
            <h6 className="mb-4 mb-0">
              <span className={"font-weight-bold mb-0"}>Contact Info</span>
            </h6>
            <div className="nav flex-column">
              <p className="nav-link mb-0">
                <i className={"fa fa-envelope"}>
                  <span className={"ml-2 font-size-14"}>
                    contact@africanbrandprotection.com
                  </span>
                </i>
              </p>
              <p className="nav-link">
                <i className={"fa fa-phone"}>
                  <span className={"ml-2 font-size-12"}>+237 699 490 983 / +237 672 16 42 01</span>
                </i>
              </p>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-12 text-center">Copyright</div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
