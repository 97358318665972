import NavBar from "../components/NavBar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CareerSection from "../components/CareerSection";

const Career = () => {
  const career = "Career";
  return (
      <>
        <NavBar bg={"bg-light"}/>
        <Header title={career}/>
        <CareerSection/>
        <Footer/>
      </>
  );
};

export default Career;
