import { useState } from "react";

const useInput = (validateValue) => {
    const [enteredValue, setEnteredValue] = useState({
        name: "",
        portable: "",
        email: "",
        company: "",
        messageText: ""
    });

    const valueIsValid = validateValue(
        enteredValue.name &&
        enteredValue.portable &&
        enteredValue.email &&
        enteredValue.company &&
        enteredValue.messageText
    );

    const valueChangeHandler = (e) => {
        const { name, value } = e.target;
        setEnteredValue((preValue) => {
            if (name === "name") {
                return {
                    name: value,
                    portable: preValue.portable,
                    email: preValue.email,
                    company: preValue.company,
                    messageText: preValue.messageText,
                };
            } else if (name === "portable") {
                return {
                    name: preValue.name,
                    portable: value,
                    email: preValue.email,
                    company: preValue.company,
                    messageText: preValue.messageText,
                };
            } else if (name === "email") {
                return {
                    name: preValue.name,
                    portable: preValue.portable,
                    email: value,
                    company: preValue.company,
                    messageText: preValue.messageText,
                };
            } else if (name === "company") {
                return {
                    name: preValue.name,
                    portable: preValue.portable,
                    email: preValue.email,
                    company: value,
                    messageText: preValue.messageText,
                };
            }else if (name === "messageText") {
                return {
                    name: preValue.name,
                    portable: preValue.portable,
                    email: preValue.email,
                    company: preValue.company,
                    messageText: value,
                };
            }
        });
    };

    const reset = () => {
        setEnteredValue({
            name: "",
            portable: "",
            email: "",
            company: "",
            messageText: "",
        });
    };

    return {
        value: enteredValue,
        isValid: valueIsValid,
        valueChangeHandler,
        reset,
    };
};

export { useInput };
