import NavBar from "../components/NavBar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import NewSection from "../components/NewSection";
import Divider from "../components/Divider";

const News = () => {
  const news = "News";
  return (
    <>
      <NavBar bg={"bg-light"} />
      <Header title={news} />
      <Divider />
      <NewSection />
      <Footer />
    </>
  );
};
export default News;
