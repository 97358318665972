import "./header.css";
import { HashLink as Link } from "react-router-hash-link";
const HeaderHome = () => {
  return (
    <header
      className="header header-rounded"
      style={{
        backgroundImage: "url(assets/img/header-background.png)",
        // opacity: "0.6",
        // zIndex: 1,
      }}
    >
      <div className="container">
        <div className="row py-10">
          <div className="col-md-6 ">
            <h1 className="display-2 font-weight-600">
              Welcome to African Brand Protection
            </h1>

            <hr
              className="w-100px ml-0 mt-0 rounded rounded-lg"
              style={{ backgroundColor: "#AA2B33", height: "4px" }}
            />
            <p className={"text-dark"}>
              One of the firms specializing in intellectual property protection
              in Africa. We have proven ourselves in several investigations
              which have enabled us to fight effectively against
              counterfAeiting. Our clients are globally recognized companies
              eager to protect their incredibly valuable brands and reputations.
            </p>

            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              to={"#text"}
              className={
                "btn btn-bg-color btn-label rounded rounded-sm text-white"
              }
            >
              <label>
                <i className="ti ti-arrow-right" />
              </label>
              Take a Tour
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};
export default HeaderHome;
