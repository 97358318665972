import { useInput } from "../hooks/useInput";
import useHttp from "../hooks/useHttp";
import urlAPI from "../hooks/helper";

const ContactSection = () => {
  const { sendRequest } = useHttp();

  const {
    value: enteredInput,
    isValid: enteredInputValid,
    valueChangeHandler: inputChangeHandler,
    reset: resetInput,
  } = useInput((value) => value.trim() !== "");

  let contactButton = false;

  if (enteredInputValid && enteredInput.portable.trim().length === 9) {
    contactButton = true;
  }

  const submitButton = () => {};

  const onSubmitted = (e) => {
    e.preventDefault();
    if (!enteredInputValid && enteredInput.email.includes("@")) {
      return;
    }
    sendRequest(
      {
        method: "POST",
        url: urlAPI + "/mail",
        headers: {
          "content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          name: enteredInput.name,
          email: enteredInput.email,
          company: enteredInput.company,
          portable: enteredInput.portable,
          messageText: enteredInput.messageText,
        },
      },
      (subscription) => {

        console.log(subscription);
      }
    ).then((r) => {
      console.log("logged: " + r);
    });
    console.table(enteredInput);
    resetInput();
  };

  return (
    <section className="section">
    <div className="container">
      <div className="row shadow-7">
        <div className="col-lg-6 p-7 mt-5">
          <h1 className={"font-weight-bold"}>Let's connect</h1>
          <hr
            className="w-100px ml-0 rounded rounded-lg mt-0"
            style={{ backgroundColor: "#AA2B33", height: "4px" }}
          />
          <p className={"text-dark"}>
            {"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium\n" +
              "doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo\n" +
              "inventore veritatis et quasi architecto beatae vitae dicta…\n"}
          </p>
          <div className="container">
            <div className={"row mt-7"}>
              <span>
                <img
                  className={"mr-2 mb-5"}
                  src={"assets/img/email.svg"}
                  alt=""
                />
              </span>
              <p className={"font-weight-bold text-dark"}>
                {"cantact@africanbrandprotection.com"}
              </p>
            </div>
            <div className={"row"}>
              <span>
                <img
                  className={"mr-2 mb-5"}
                  src={"assets/img/phone.svg"}
                  alt=""
                />
              </span>
              <p className={"font-weight-bold text-dark"}>
                {"+237 699 490 983"}
              </p>
            </div>
            <div className={"row"}>
              <span>
                <img
                  className={"mr-2"}
                  src={"assets/img/mapMarker.svg"}
                  alt=""
                />
              </span>
              <p className={"font-weight-bold text-dark"}>
                {"Nsimeyong, Yaoundé"}
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 border border-secondary bg-secondary p-7">
          <h1 className={"font-weight-bold"}>Send Us a message</h1>
          <hr
            className="w-100px ml-0 mt-0 rounded rounded-lg"
            style={{ backgroundColor: "#AA2B33", height: "4px" }}
          />
          <form onSubmit={onSubmitted}>
            <div className="row">
              <div className="col">
                <label htmlFor="">
                  First & Last Name{" "}
                  <span className={"text-danger"}>{" *"}</span>
                </label>
                <input
                  name={"name"}
                  value={enteredInput.Name}
                  type="text"
                  className="form-control"
                  onChange={inputChangeHandler}
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col">
                <label htmlFor="">
                  Phone number <span className={"text-danger"}>{" *"}</span>
                </label>
                <input
                  name={"portable"}
                  value={enteredInput.portable}
                  type="text"
                  className="form-control"
                  onChange={inputChangeHandler}
                />
              </div>
              <div className="col">
                <label htmlFor="">
                  Email address <span className={"text-danger"}>{" *"}</span>
                </label>
                <input
                  name={"email"}
                  value={enteredInput.email}
                  type="email"
                  className="form-control"
                  onChange={inputChangeHandler}
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col">
                <label htmlFor="">Company name (optional) </label>
                <input
                  name={"company"}
                  value={enteredInput.company}
                  type="text"
                  className="form-control"
                  onChange={inputChangeHandler}
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col">
                <label>
                  Message <span className={"text-danger"}>{" *"}</span>
                </label>
                <textarea
                  className={"form-control mb-5"}
                  name="messageText"
                  value={enteredInput.messageText}
                  onChange={inputChangeHandler}
                  id=""
                  rows="4"
                />
              </div>
            </div>
            <button
              onClick={submitButton}
              className={"btn btn-bg-color text-white"}
              disabled={!contactButton}
            >
              Contact us now!
            </button>
          </form>
        </div>
      </div>
    </div>
    </section>
  );
};
export default ContactSection;
