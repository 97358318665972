import { Link } from "react-router-dom";

const NavBar = (props) => {
  return (
    <nav className={"navbar navbar-expand-lg navbar-dark " + props.bg}>
      <div className="container">
        <div className="navbar-left">
          {/*<button className="navbar-toggler" type="button">*/}
          {/*  <span className="navbar-toggler-icon" />*/}
          {/*</button>*/}
          <Link to="/">
            <img
              width={"100px"}
              className="logo-dark"
              src={"assets/img/logo/png/Plandetravail4@3x.png"}
              alt="logo"
            />
          </Link>
        </div>

        <section className="navbar-mobile">
          <nav className="nav nav-navbar ml-auto">
            {/*<NavLink*/}
            {/*  className="nav-link"*/}
            {/*  activeClassName={classes.active}*/}
            {/*  to={"/"}*/}
            {/*>*/}
            {/*  Home*/}
            {/*</NavLink>*/}
            {/*<NavLink*/}
            {/*  className="nav-link"*/}
            {/*  activeClassName={classes.active}*/}
            {/*  to={"/whatwedo"}*/}
            {/*>*/}
            {/*  What we do*/}
            {/*</NavLink>*/}
            {/*<NavLink*/}
            {/*  className="nav-link"*/}
            {/*  activeClassName={classes.active}*/}
            {/*  to={"/news"}*/}
            {/*>*/}
            {/*  News*/}
            {/*</NavLink>*/}
            {/*<NavLink*/}
            {/*  className="nav-link mr-6"*/}
            {/*  activeClassName={classes.active}*/}
            {/*  to={"/career"}*/}
            {/*>*/}
            {/*  Career*/}
            {/*</NavLink>*/}
          </nav>
        </section>
        <Link to={"/contact"} className={"btn btn-bg-color text-white"}>
          Contact Us
        </Link>
        {/*<button*/}
        {/*  className={"ml-4"}*/}
        {/*  style={{*/}
        {/*    textDecoration: "none",*/}
        {/*    border: "none",*/}
        {/*    backgroundColor: "transparent",*/}
        {/*  }}*/}
        {/*  onClick={() => {*/}
        {/*    console.log("Fr");*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Fr*/}
        {/*</button>*/}
      </div>
    </nav>
  );
};
export default NavBar;
