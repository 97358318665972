import NavBar from "../components/NavBar";
import HeaderHome from "../components/HeaderHome";
import Footer from "../components/Footer";
import About from "../components/About";
import Text from "../components/Text";
import Team from "./Team";
// import OurCustomers from "../components/OurCustomers";

const Home = () => {
  return (
    <>
      <NavBar />
      <HeaderHome />
      <Text />
      <About />
      <Team />
      {/*<OurCustomers />*/}
      <Footer />
    </>
  );
};
export default Home;
