import Divider from "./Divider";

const NewSection = () => {
  return (
    <section
      style={{
        backgroundImage: "url(assets/img/news.png)",
        backgroundPosition: "right top",
      }}
      className="section"
    >
      <div className="container">
        <div className="row mb-8">
          <div className="col-md-6 col-lg-6">
            <span className={"letter-spacing-3"}>category tag</span>
            <h1 className={"font-weight-bold display-2"}>News Section</h1>
            <p className={"text-dark"}>
              Later, when I would meet Binna and learn about his Port Douglas,
              I’d think back to these modern and contemporary canvases.{" "}
              <span className={"text-danger"}>Read more</span>
            </p>
          </div>
          <div className="col-md-6 col-lg-6">
            <img src={"assets/img/img.png"} alt="" />
          </div>
        </div>
        <Divider/>
        <div className="row">
          <div className="col-md-6 col-lg-6">
            <span className={"letter-spacing-3"}>category tag</span>
            <h1 className={"font-weight-bold display-2"}>News Section</h1>
            <p className={"text-dark"}>
              Later, when I would meet Binna and learn about his Port Douglas,
              I’d think back to these modern and contemporary canvases.{" "}
              <span className={"text-danger"}>Read more</span>
            </p>
          </div>
          <div className="col-md-6 col-lg-6">
            <img src={"assets/img/img.png"} alt="" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-6">
            <span className={"letter-spacing-3"}>category tag</span>
            <h1 className={"font-weight-bold display-2"}>News Section</h1>
            <p className={"text-dark"}>
              Later, when I would meet Binna and learn about his Port Douglas,
              I’d think back to these modern and contemporary canvases.{" "}
              <span className={"text-danger"}>Read more</span>
            </p>
          </div>
          <div className="col-md-6 col-lg-6">
            <img src={"assets/img/img.png"} alt="" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-6">
            <span className={"letter-spacing-3"}>category tag</span>
            <h1 className={"font-weight-bold display-2"}>News Section</h1>
            <p className={"text-dark"}>
              Later, when I would meet Binna and learn about his Port Douglas,
              I’d think back to these modern and contemporary canvases.{" "}
              <span className={"text-danger"}>Read more</span>
            </p>
          </div>
          <div className="col-md-6 col-lg-6">
            <img src={"assets/img/img.png"} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};
export default NewSection;
