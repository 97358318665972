const WhatWeDoSection = () => {
  return (
    <section className="section">
      <div className="container">
        <div className="row text-center">
          <div className="col-12">
            <h1 className="font-weight-bold">{"Our Services"}</h1>
            <p>
              {"Learn how we helped our several clients grow in online\n" +
                "business. It will give you an idea of our capabilities."}
            </p>
          </div>
        </div>
        <div className="row mt-10">
          <div className="col-lg-3 col-md-3 text-center shadow-9 rounded-lg">
            <div className="card">
              <h1 className="card-title">{"Lorem ipsum"}</h1>
              <div className="card-body">
                <img src={"assets/img/services.svg"} alt="" />
                <p>
                  {"Suspendisse dignissim ac est\n" +
                    "rutrum rutrum. Aliquam\n" +
                    "pulvinar imperdiet turpis, eu\n" +
                    "vestibulum lacus pulvinar nec.\n"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default WhatWeDoSection;
