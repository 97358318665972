const Header = (props) => {
  return (
    <header
      style={{
        backgroundImage: "url(assets/img/headerBackground.png)",
      }}
      className={"header text-white mt-4 mb-8"}
    >
      <div className="container">
        <div className="row py-12">
          <div className="col-12 text-center">
            <h1 className="display-2 font-weight-bold ">{props.title}</h1>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
