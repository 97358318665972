import NavBar from "../components/NavBar";
import Header from "../components/Header";
import ContactSection from "../components/ContactSection";
import Footer from "../components/Footer";

const Contact = () => {
  const contact = "Contact Us"
  return (
      <>
        <NavBar bg={"bg-light"}/>
        <Header title={contact}/>
        <ContactSection/>
        <Footer/>
      </>
  );
};
export default Contact;
