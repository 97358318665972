const Divider = () => {
  return (
    <div className="container">
      <hr
        className="w-1000px ml-0 rounded rounded-lg mt-0"
        style={{ backgroundColor: "#dfdbdb", height: "1px" }}
      />
    </div>
  );
};
export default Divider;
